var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
var counter = 0;
var buckets = {};

var setHarmonicInterval = function (fn, ms) {
  var _a;

  var id = counter++;

  if (buckets[ms]) {
    buckets[ms].listeners[id] = fn;
  } else {
    var timer = setInterval(function () {
      var listeners = buckets[ms].listeners;
      var didThrow = false;
      var lastError;

      for (var _i = 0, _a = Object.values(listeners); _i < _a.length; _i++) {
        var listener = _a[_i];

        try {
          listener();
        } catch (error) {
          didThrow = true;
          lastError = error;
        }
      }

      if (didThrow) throw lastError;
    }, ms);
    buckets[ms] = {
      ms: ms,
      timer: timer,
      listeners: (_a = {}, _a[id] = fn, _a)
    };
  }

  return {
    bucket: buckets[ms],
    id: id
  };
};

var clearHarmonicInterval = function (_a) {
  var bucket = _a.bucket,
      id = _a.id;
  delete bucket.listeners[id];
  var hasListeners = false;

  for (var listener in bucket.listeners) {
    hasListeners = true;
    break;
  }

  if (!hasListeners) {
    clearInterval(bucket.timer);
    delete buckets[bucket.ms];
  }
};

exports.clearHarmonicInterval = clearHarmonicInterval;
exports.setHarmonicInterval = setHarmonicInterval;
export default exports;
export const __esModule = exports.__esModule;
const _clearHarmonicInterval = exports.clearHarmonicInterval,
      _setHarmonicInterval = exports.setHarmonicInterval;
export { _clearHarmonicInterval as clearHarmonicInterval, _setHarmonicInterval as setHarmonicInterval };